import { type LanguagesConfig } from '../interface/config';

const getLanguagesConfig = (siteName: string): LanguagesConfig => {
  return {
    title: {
      SV: 'Om den här annonsen',
      NO: 'Om denne annonsen',
      DK: 'Om denne annonce',
      FI: 'Tietoja tästä mainoksesta',
      EN: 'About this ad'
    },
    family: {
      SV: `${siteName} är en del av Schibsted Media`,
      NO: `${siteName} er en del av Schibsted Media`,
      DK: `${siteName} er en del af Schibsted Media`,
      FI: `${siteName} on osa Schibsted Media`,
      POLARIS: `${siteName} samarbeider med Schibsted Media`,
      EN: `${siteName} is part of the Schibsted Media`
    },
    close: {
      SV: 'Stäng',
      NO: 'Lukk',
      DK: 'Luk',
      FI: 'Sulje',
      EN: 'Close'
    },
    why: {
      SV: 'Varför ser du denna annons?',
      NO: 'Hvorfor ser du denne annonsen?',
      DK: 'Hvorfor ser du denne annonce?',
      FI: 'Miksi tämä mainos näkyy sinulle?',
      EN: 'Why are you seeing this ad?'
    },
    whyMarketplace: {
      SV: 'Varför ser du denna reklam?',
      NO: 'Hvorfor ser du denne reklamen?',
      DK: 'Hvorfor ser du denne reklame?',
      FI: 'Miksi tämä mainos näkyy sinulle?'
    },
    whyExplained: {
      SV: 'Du ser denna annons eftersom sajten du besöker till stor del finansieras  av annonsintäkter. Vissa annonser ser du för att vi tror att de kan  intressera dig. Detta antagande baserar vi på din tidigare aktivitet på Schibsteds sajter och appar.',
      NO: 'Du ser denne annonsen ettersom nettstedet du besøker til stor del finansieres av annonseinntekter. Noen annonser ser du fordi vi tror de kan interessere deg. Vi antar dette basert på din tidligere aktivitet på Schibsteds nettsteder og apper.',
      DK: 'Du ser denne annonce eftersom siden du besøger i høj grad finansieres af annonceindtægter. Nogle annoncer vises, fordi vi tror de kan interessere dig. Vi antager dette baseret på din tidligere aktivitet på Schibsteds hjemmesider og apps.',
      FI: 'Näet tämän mainoksen, koska vierailemasi sivusto rahoitetaan suurelta osin mainostuloilla. Näet osan mainoksista, koska oletamme niiden kiinnostavan sinua. Tämän olettamus perustuu aiempaan toimintaasi Schibstedin verkkosivustoilla ja sovelluksissa.',
      POLARIS:
        'Du ser denne annonsen ettersom nettstedet du besøker til stor del finansieres av annonseinntekter. Noen annonser ser du fordi vi tror de kan interessere deg. Vi antar dette basert på din tidligere aktivitet på Schibsted og samarbeidende mediehus sine nettsteder og apper.',
      EN: "You are seeing this ad because the website you are visiting is largely funded by advertising revenue. You see some ads because we think they might interest you. We assume this based on your previous activity on Schibsted's websites and apps."
    },
    whyExplainedMore: {
      SV: 'Vi samarbetar också med vissa annonsörer om annonsprodukten Schibsted Match. Inom ramarna för Schibsted Match delar annonsören antingen kundernas e-postadress eller telefonnummer med Schibsted för att bygga en anpassad målgrupp. Under den processen skickar Schibsted inte användardata till annonsören.',
      NO: 'Vi samarbeider også med enkelte annonsører om annonseproduktet Schibsted Match. Innenfor rammene av Schibsted Match deler annonsøren enten e-postadressen eller telefonnummeret til sine kunder med Schibsted, for å bygge opp en tilpasset målgruppe. I den prosessen, sender ikke Schibsted brukerdata til annonsøren.',
      EN: 'We also work with certain advertisers on the advertising product Schibsted Match. Schibsted Match allows the advertiser to share information about its users with Schibsted, in order to build up a customised target group. Schibsted does not share user data with the advertiser.'
    },
    manageExplained: {
      SV: 'Du kan ge ditt samtycke eller neka behandling av dina aktivitetsdata för anpassad annonsering via cookieinställningarna som du hittar på webbplatsen du besöker eller i inställningarna för appen du använder.',
      NO: 'Du kan samtykke til eller avvise behandlingen av dine aktivitetsdata til tilpasset annonsering gjennom samtykkeforespørselen, som du finner på nettsiden du ser på eller i innstillingene til appen du bruker.',
      EN: 'You can consent to or reject the processing of personal data for targeted advertising based on your activity data in the cookie settings/samtykkeinnstillinger available from the page you are viewing or the settings in the mobile application you are using.'
    },
    manageExplainedMore: {
      SV: 'För alla andra typer av data, såsom kontoinformation kopplad till ditt Schibsted-konto, kan du uppdatera dina val för personanpassade annonser i sekretessinställningarna',
      NO: 'For alle andre typer data, som kontoinformasjonen tilknyttet din Schibsted-konto, kan du oppdatere dine valg for personaliserte annonser i personverninnstillingene',
      EN: 'For all other types of data, such as your Schibsted account data, you can update your ad preferences in privacy settings'
    },
    manageHeading: {
      SV: 'Hantera dina annonsinställningar',
      NO: 'Administrer dine annonseinnstillinger',
      DK: 'Administrer dine annonceindstillinger',
      FI: 'Muokkaa mainosasetuksiasi',
      EN: 'Manage your ad settings'
    },
    takeControl: {
      SV: 'Styr själv över vilka annonser du ser genom att ändra dina inställningar.',
      NO: 'Du kan selv styre hvilke annonser du ser ved å endre dine innstillinger.',
      DK: 'Du kan selv styre hvilke annoncer du ser ved at ændre dine indstillinger.',
      FI: 'Voit itse hallita näkemiäsi mainoksia muuttamalla asetuksiasi.'
    },
    aboutSchibsted: {
      SV: 'Om Schibsted och annonsering',
      NO: 'Om Schibsted og annonsering',
      DK: 'Om Schibsted og annoncering',
      FI: 'Tietoja mainonnasta Schibstedillä',
      EN: 'About Schibsted and advertising'
    },
    smokeTest: {
      SV: '',
      NO: 'Skjul annonsen midlertidig',
      DK: '',
      FI: ''
    },
    smokeTestURL: {
      SV: '',
      NO: 'https://docs.google.com/forms/d/e/1FAIpQLSdS1Mbdz1Qw870JNRoYK3qp5u0EFta9H-NJywi-yncUc7duQg/viewform',
      DK: '',
      FI: ''
    },
    hideAd: {
      SV: 'Dölj din annons tillfälligt',
      NO: 'Skjul annonsen midlertidig',
      DK: 'Læs mere om annoncering på Schibsted',
      FI: 'Piilota mainos väliaikaisesti',
      EN: 'Hide the ad Temporarily'
    },
    annonseId: {
      SV: 'Annonsens ID-nummer',
      NO: 'Annonsens ID-nummer',
      DK: 'Annoncens ID-nummer',
      FI: 'Ilmoituksen ID-numero',
      EN: 'Ad ID'
    },
    advertiserHeader: {
      SV: 'Om annonsören',
      NO: 'Om annonsøren',
      DK: 'Om annoncøren',
      FI: 'Tietoja mainostajasta',
      EN: 'About the advertiser'
    },
    advertiserName: {
      SV: 'Detta är en annons för:',
      NO: 'Dette er en annonse for:',
      DK: 'Dette er en annonce for:',
      FI: 'Tämä on mainos:'
    }
  };
};

export { getLanguagesConfig };
