import { type SiteConfig, type Config } from '../interface/config';

const getSiteConfig = (): Config => {
  const parts: string[] = window.location.host.split('.');
  const host: string = parts.at(-2);
  const config: Config = siteConfig[host];

  if (!config) {
    return {
      name: host,
      pulseID: host
    };
  }

  if (!config.name) {
    config.name = host;
  }

  return config;
};

const siteConfig: SiteConfig = {
  adressa: { name: 'Adresseavisen', polaris: true, pulseID: 'adressano' },
  aesby: { name: 'Aesby', polaris: true },
  agderposten: { name: 'Agderposten', polaris: true, pulseID: 'agderposten' },
  aftenbladet: { name: 'Stavanger Aftenblad', allowHidingAds: true, pulseID: 'stavangeraftenblad' },
  aftonbladet: { name: 'Aftonbladet', customColor: '#585858', pulseID: 'aftonbladet' },
  aftenposten: { name: 'Aftenposten', pulseID: 'aftenposten' },
  altaposten: { polaris: true, allowHidingAds: true, pulseID: 'altaposten' },
  alvdalmiv: { name: 'Alvdal Midt i Væla', polaris: true },
  'andalsnes-avis': { name: 'Åndalsnes Avis', polaris: true, pulseID: 'andalsnes-avis' },
  'av-avis': { name: 'Askøyværingen', polaris: true, allowHidingAds: true, pulseID: 'askoyvaeringen' },
  'avisa-hordaland': { name: 'Avisa Hordaland', polaris: true },
  'avisa-st': { name: 'Avisa Sør-Trøndelag', polaris: true, pulseID: 'avisa-st' },
  banett: { name: 'Brønnøysunds Avis', polaris: true, pulseID: 'banett' },
  bilbasen: {
    name: 'BILBASEN',
    marketplace: true,
    allowHidingAds: true,
    pulseID: 'bilbasen'
  },
  bladet: { name: 'Bladet', polaris: true, pulseID: 'bladet' },
  blv: { name: 'Bladet Vesterålen', polaris: true },
  'bomlo-nytt': { name: 'Bømlo-nytt', polaris: true },
  blocket: { marketplace: true, pulseID: 'blocket', name: 'Blocket' },
  bt: { name: 'Bergens Tidende', allowHidingAds: true, pulseID: 'bt' },
  dagen: { name: 'Dagen', polaris: true },
  dagsavisen: { name: 'Dagsavisen', polaris: true },
  dba: {
    name: 'DBA',
    marketplace: true,
    allowHidingAds: true,
    pulseID: 'dba'
  },
  dolen: { name: 'Dølen', polaris: true, pulseID: 'dolen' },
  driva: { polaris: true, pulseID: 'driva' },
  eub: { name: 'Eidsvoll Ullensaker Blad', polaris: true },
  firdatinend: { name: 'Firda Tidend', polaris: true },
  finn: { name: 'FINN', allowHidingAds: true, pulseID: 'finn' },
  fjordabladet: { polaris: true, pulseID: 'fjordabladet' },
  fjordingen: { polaris: true, pulseID: 'fjordingen' },
  fjuken: { polaris: true, pulseID: 'fjuken' },
  fjt: { name: 'Fjordenes Tidene', polaris: true, pulseID: 'fjtno' },
  'fosna-folket': { name: 'Fosna Folket', polaris: true, pulseID: 'fosna-folket' },
  folkebladet: { polaris: true, pulseID: 'folkebladet' },
  framtidinord: { name: 'Framtid i Nord', polaris: true, pulseID: 'framtidinord' },
  frostingen: { name: 'Frostingen', polaris: true },
  froya: { name: 'Frøya', polaris: true },
  fvn: {
    name: 'Fædrelandsvennen',
    polaris: true,
    allowHidingAds: true,
    pulseID: 'faedrelandsvennen'
  },
  gat: { name: 'Grimstad Adressetidende', polaris: true, pulseID: 'grimstad-adressetidende' },
  gauldalsposten: { name: 'Gauldalsposten', polaris: true },
  godare: { name: 'Godare', allowHidingAds: true },
  grenda: { name: 'Grenda', polaris: true, pulseID: 'grenda' },
  gjoviksblad: { name: 'Gjøviksblad', polaris: true },
  hallingdolen: { name: 'Hallingdølen', polaris: true },
  'h-a': { name: 'Hamar Arbeiderblad', polaris: true },
  hblad: { name: 'Helgelandsblad', polaris: true },
  hf: { name: 'Hordaland Folkeblad', polaris: true },
  'hitra-froya': { name: 'Hitra-Frøya', polaris: true, pulseID: 'hitra-froya' },
  ht: { name: 'Harstad Tidene', polaris: true, pulseID: 'htno' },
  inderoyningen: { name: 'Inderøyningen', polaris: true },
  innherred: { polaris: true, pulseID: 'innherred' },
  itromso: { name: 'iTromsø', polaris: true, pulseID: 'itromso' },
  'l-a': {
    name: 'Lindesnes',
    polaris: true,
    allowHidingAds: true,
    pulseID: 'lindesnes'
  },
  klebuposten: { name: 'Klæbuposten', polaris: true },
  klart: {
    links: {
      READ_MORE_ADVERTISING: {
        url: 'https://www.klart.se/datahantering-annonser/',
        text: 'Läs mer om annonsering på Schibsted'
      },
      MANAGE_SETTINGS: { url: 'https://www.klart.se/datahantering-annonser/', text: 'Hantera dina annonsinställningar' }
    },
    pulseID: 'klartse'
  },
  kvinesdal24: { name: 'Kvinesdal24', polaris: true },
  lister24: { name: 'Lister24', polaris: true },
  'lokal-avisa': { name: 'Lokalavisa Trysil - Engerdal', polaris: true },
  lp: { name: 'Lillesands-Posten', polaris: true, pulseID: 'lp' },
  minmote: { name: 'Min Mote', pulseID: 'minmote' },
  mn24: { name: 'MN24', polaris: true },
  morenytt: { name: 'Møre-Nytt', polaris: true, pulseID: 'morenytt' },
  moteplassen: { name: 'Møteplassen', pulseID: 'moteplassencom' },
  namdalsavisa: { polaris: true, pulseID: 'namdalsavisa' },
  'nye-troms': { name: 'Nye Troms', polaris: true },
  nyss: { name: 'Nyss', polaris: true },
  oikotie: { name: 'OIKOTIE', allowHidingAds: true, pulseID: 'oikotie' },
  opp: { polaris: true, pulseID: 'opp' },
  osogfusa: { name: 'Os og Fusaposten', polaris: true },
  polarismedia: { name: 'Polarismedia', polaris: true },
  rakentaja: { name: 'RAKENTAJA', allowHidingAds: true, pulseID: 'rakentaja' },
  raumnes: { name: 'Raumnes', polaris: true },
  rbnett: { name: 'Romsdals Budstikke', polaris: true, pulseID: 'rbnett' },
  ringsakern: { name: 'Ringsakern', polaris: true },
  setesdolen: { name: 'Setesdølen', polaris: true },
  smp: { name: 'Sunnmørsposten', polaris: true, pulseID: 'smpno' },
  snasningen: { name: 'Snåsningen', polaris: true },
  stangeavisa: { name: 'Stangeavisa', polaris: true },
  'steinkjer-avisa': { name: 'Steinkjer-Avisa', polaris: true },
  steinkjer24: { name: 'Steinkjer24', polaris: true },
  sunnmoringen: { name: 'Sunnmøringen', polaris: true, pulseID: 'sunnmoringen' },
  suldalsposten: { name: 'Suldalsposten', polaris: true },
  sunnhordland: { name: 'Sunnhordland', polaris: true },
  sva: { name: 'Sør- Varanger Avis', polaris: true },
  svd: { name: 'Svenska Dagbladet', pulseID: 'SVDSE' },
  's-n': { name: 'Stjørdals-nytt', polaris: true },
  't-a': { name: 'Trønder-Avisa', polaris: true, pulseID: 'polaris-tronderavisa' },
  tori: {
    name: 'TORI',
    marketplace: true,
    allowHidingAds: true,
    pulseID: 'tori'
  },
  totensblad: { name: 'Totens blad', polaris: true },
  tronderbladet: { name: 'Trønderbladet', polaris: true, pulseID: 'tronderbladet' },
  trdevents: { name: 'Trdevents', polaris: true },
  tv: { name: 'tv.nu', pulseID: 'tvnu' },
  tynsetingen: { name: 'Tynsetingen', polaris: true },
  tysnesbladet: { name: 'Tysnes Bladet', polaris: true },
  vaganavisa: { name: 'Våganavisa', polaris: true },
  vaksdalposten: { name: 'Vaksdalposten', polaris: true },
  varden: { name: 'Varden', polaris: true, pulseID: 'varden' },
  venneslatidende: { name: 'Vennesla Tidende', polaris: true, pulseID: 'vennesla-tidende' },
  vestlandsnytt: { polaris: true, pulseID: 'vestlandsnytt' },
  vtb: { name: 'Vest- Telemark Blad', polaris: true },
  vg: { name: 'VG', allowHidingAds: true, pulseID: 'vg' },
  vglive: { name: 'VG Live', allowHidingAds: true, pulseID: 'vglive' },
  vigga: { polaris: true, pulseID: 'vigga' },
  vikebladet: { name: 'Vikebladet Vestposten', polaris: true, pulseID: 'vikebladet' },
  vol: { polaris: true, pulseID: 'volno' },
  oyposten: { polaris: true, name: 'Øyposten' },
  porten: { polaris: true, name: 'Porten' },
  randaberg24: { polaris: true, name: 'Randaberg24' },
  stord24: { polaris: true, name: 'Stord24' },
  strilen: { polaris: true, name: 'Strilen' },
  vestnytt: { polaris: true, name: 'Vestnytt' },
  marsteinen: { polaris: true, name: 'Marsteinen' },
  nearadio: { polaris: true, name: 'Nea Radio' },
  kystogfjord: { polaris: true, name: 'Kyst og Fjord' },
  kulingen: { polaris: true, name: 'Kulingen' },
  fitjarposten: { polaris: true, name: 'Fitjarposten' },
  bygdanytt: { polaris: true, name: 'Bygdanytt' }
};

export { siteConfig, getSiteConfig, type Config };
