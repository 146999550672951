import { isLuckyUser } from '../../../utils/url';
import { type Config, type LanguagesConfig } from '../../../interface/config';
import { type Links } from '../../../interface/links';
import type { RendererModalParams } from '../../../interface/modal';

class ModalBuilder {
  private locale: string;
  readonly languagesConfig: LanguagesConfig;
  readonly isPolarisSite: boolean = false;
  readonly isHidingAdsAllowed: boolean = false;
  readonly privacyLinks: Links = {};
  readonly sections: string[] = [];

  constructor(rendererModalParams: RendererModalParams) {
    const { siteConfig, languagesConfig, locale, privacyLinks } = rendererModalParams;
    const { polaris = false, allowHidingAds } = siteConfig;
    const globalConfig: Config = window.gdpr || {};

    this.locale = locale;
    this.languagesConfig = languagesConfig;
    this.isPolarisSite = polaris;
    this.isHidingAdsAllowed = allowHidingAds || globalConfig.allowHidingAds || false;
    this.privacyLinks = privacyLinks;
  }

  addCustomLocale(locale: string): this {
    this.locale = locale;

    return this;
  }

  addHeader(headerOrgText: string): this {
    const headerSiteText = this.languagesConfig.title[this.locale];
    const logoHeaderHTML = `<a class='gdpr-modal__header-logo' href="https://schibsted.com" target="_blank" rel="noopener" title="schibsted"></a>`;
    const titleHeaderHTML = `<div class='gdpr-modal__header-site-name'>${headerSiteText}</div>`;
    const orgHeaderHTML = `<div class='gdpr-modal__header-org-name'>${headerOrgText}</div>`;

    this.sections.push(
      `<div id='gdpr-modal__label' class='gdpr-modal__header'>${logoHeaderHTML}${titleHeaderHTML}${orgHeaderHTML}</div>`
    );

    return this;
  }

  addSmokeTest(): this {
    if (isLuckyUser()) {
      const { smokeTestURL, smokeTest } = this.languagesConfig;
      this.sections.push(
        `<div class='gdpr-modal__smoke-test'>
          <span class='gdpr-modal__smoke-test-name'>
            <a id='gdpr-modal__hide-ad' target='_blank' href="${smokeTestURL[this.locale]}">
              ${smokeTest[this.locale]}
            </a>
            <div id='gdpr-modal__eye-icon' class='gdpr-modal__eye-icon'></div>
          </span>
        </div>`
      );
    }

    return this;
  }

  addInfoSectionHeader(paragraph): this {
    this.sections.push(
      `<div>
                <p class='gdpr-modal__infobox-header' data-testid="gdpr-modal-header">${paragraph}</p>
            </div>`
    );

    return this;
  }

  addInfoSection(paragraph: string): this {
    this.sections.push(
      `<div class='gdpr-modal__infobox-section-inner'>
                <p class='gdpr-modal__infobox-text' data-testid="gdpr-modal-text">${paragraph}</p>
            </div>`
    );

    return this;
  }

  addInfoSectionMore(paragraph: string): this {
    const { url, text } = this.privacyLinks[this.locale].READ_MORE_MATCH_ADVERTISES;

    const link = url
      ? `<a href='${url}' target='_blank' rel='noopener' class='gdpr-modal__link' id='gdpr-modal__link-read-more'>
                <span>${text}</span>
               </a>`
      : '';

    this.sections.push(
      `<div class='gdpr-modal__infobox-section'>
                <p class='gdpr-modal__infobox-text' data-testid="gdpr-modal-text">${paragraph}${link}</p>
            </div>`
    );

    return this;
  }

  addAdSettingsSection(manageHeaderText: string, manageText: string, manageMoreText: string): this {
    const { url, text } = this.privacyLinks[this.locale].PRIVACY_SETTINGS;
    const link = url
      ? `<a href='${url}' target='_blank' rel='noopener' id='gdpr-modal__link-settings' class='gdpr-modal__link'>
          <span>${text}</span>
        </a>`
      : '';

    this.sections.push(
      `<div class='gdpr-modal__infobox-section'>
            <p class='gdpr-modal__infobox-header' data-testid="gdpr-modal-header">${manageHeaderText}</p>
            <p class='gdpr-modal__infobox-text' data-testid="gdpr-modal-text">${manageText}</p>
            <p class='gdpr-modal__infobox-text' data-testid="gdpr-modal-text">${manageMoreText}${link}</p>
        </div>`
    );

    return this;
  }

  addLinkSettings(): this {
    const { url, text } = this.privacyLinks[this.locale].MANAGE_SETTINGS;
    const linksSettingsHTML = url
      ? `<div class='gdpr-modal__link-wrapper'><a href='${url}' target='_blank' rel='noopener' id='gdpr-modal__link-settings' class='gdpr-modal__link'>
          <span>${text}</span>
        </a></div>`
      : '';

    this.sections.push(linksSettingsHTML);

    return this;
  }

  addReadMoreSchibstedSection(): this {
    const { url, text } = this.privacyLinks[this.locale].READ_MORE_ADVERTISING;
    const linksReadmoreHTML = url
      ? `<a href='${url}' target='_blank' rel='noopener' id='gdpr-modal__link-read-more' class='gdpr-modal__link'>
                <span>${text}</span>
               </a>`
      : '';

    this.sections.push(
      `<div class='gdpr-modal__infobox-section'>
                <p class='gdpr-modal__infobox-header'>${this.languagesConfig.aboutSchibsted[this.locale]}</p>
                ${linksReadmoreHTML}
            </div>`
    );

    return this;
  }

  addAdIdSection(): this {
    const advertiserHeader = this.languagesConfig.advertiserHeader[this.locale];
    const creativeId = this.languagesConfig.annonseId[this.locale];
    const advertiserName = this.languagesConfig.advertiserName[this.locale];

    this.sections.push(
      `<div class='gdpr-modal__advertiser' data-testid="gdpr-modal-advertiser">
                ${advertiserHeader ? `<p class='gdpr-modal__advertiser-header'>${advertiserHeader}</p>` : ''}
                <p class="gdpr-modal__advertiser-creativeId">
                    <span>${creativeId}:</span>
                    <span id="gdprAdTransparencyModal__creativeId"></span>
                </p>
                ${advertiserName ? `<p class="gdpr-modal__advertiser-advertiser"><span>${advertiserName}</span><span id="gdpr-modal__advertiser-name"></span></p>` : ''}
            </div>`
    );

    return this;
  }

  addBottomButtonSection(): this {
    const closeButtonHTML = `<div id='gdpr-modal__close-button' data-testid="gdpr-modal-close" class='gdpr-modal__close-button' aria-label="${this.languagesConfig.close[this.locale]}" role='button'>${this.languagesConfig.close[this.locale]}</div>`;
    const hideAdsHTML = this.isHidingAdsAllowed
      ? `<div class='gdpr-modal__hide-ads' role="button" data-testid="modal-hide-ads">
                <span class='gdpr-modal__hide-ads-label'>${this.languagesConfig.hideAd[this.locale]}</span>
                <span class='gdpr-modal__eye-icon' title="${this.languagesConfig.hideAd[this.locale]}"></span>
            </div>`
      : '';

    this.sections.push(`<div class='gdpr-modal__buttons-section'>${closeButtonHTML}${hideAdsHTML}</div>`);

    return this;
  }

  build(): string {
    const bottomSection = this.sections.pop();

    return `
      <div id='gdprAdTransparencyModal' data-testid="gdpr-modal" class="gdpr-modal ${this.isHidingAdsAllowed ? 'gdpr-modal--hiding-ads' : ''}" role="dialog" aria-modal aria-labelledby="gdpr-modal__label" style="display: none">
        <div class='gdpr-modal__content'>
          <div class='gdpr-modal__content-top'>
            ${this.sections.join('')}
          </div>
          <div class='gdpr-modal__content-bottom'>
            ${bottomSection}
          </div>
        </div>
      </div>
    `;
  }
}

export { ModalBuilder };
