import { type RendererModalParams } from '../../../interface/modal';
import { ModalBuilder } from './builder';

const removeOldModalIfNeeded = (): void => {
  const modal = document.querySelector<HTMLElement>('#gdprAdTransparencyModal');

  if (modal) {
    modal.remove();
  }
};

const buildModal = (rendererModalParams: RendererModalParams): ModalBuilder => {
  const { languagesConfig, locale } = rendererModalParams;
  const supportedLanguages: string[] = ['NO', 'SV', 'EN'];
  const language = supportedLanguages.includes(locale) ? locale : 'EN';

  const infoboxHeaderText: string = languagesConfig.why[language];
  const explanationHeaderText: string = languagesConfig.whyExplained[language];
  const explanationMoreHeaderText: string = languagesConfig.whyExplainedMore[language];
  const manageHeaderText: string = languagesConfig.manageHeading[language];
  const manageText: string = languagesConfig.manageExplained[language];
  const manageMoreText: string = languagesConfig.manageExplainedMore[language];
  const headerOrgText: string = languagesConfig.family[language];

  return new ModalBuilder(rendererModalParams)
    .addCustomLocale(language)
    .addHeader(headerOrgText)
    .addSmokeTest()
    .addInfoSectionHeader(infoboxHeaderText)
    .addInfoSection(explanationHeaderText)
    .addInfoSectionMore(explanationMoreHeaderText)
    .addAdSettingsSection(manageHeaderText, manageText, manageMoreText)
    .addReadMoreSchibstedSection()
    .addAdIdSection()
    .addBottomButtonSection();
};

const buildModalMarketPlaces = (rendererModalParams: RendererModalParams): ModalBuilder => {
  const { languagesConfig, locale } = rendererModalParams;

  const infoboxHeaderText: string = languagesConfig.whyMarketplace[locale];
  const explanationHeaderText: string = languagesConfig.whyExplained[locale];
  const headerOrgText: string = languagesConfig.family[locale];

  return new ModalBuilder(rendererModalParams)
    .addHeader(headerOrgText)
    .addSmokeTest()
    .addInfoSectionHeader(infoboxHeaderText)
    .addInfoSection(explanationHeaderText)
    .addLinkSettings()
    .addReadMoreSchibstedSection()
    .addAdIdSection()
    .addBottomButtonSection();
};

const buildModalPolaris = (rendererModalParams: RendererModalParams): ModalBuilder => {
  const { languagesConfig, locale } = rendererModalParams;

  const infoboxHeaderText = languagesConfig.why[locale];
  const explanationHeaderText = languagesConfig.whyExplained.POLARIS;
  const headerOrgText: string = languagesConfig.family.POLARIS;

  return new ModalBuilder(rendererModalParams)
    .addHeader(headerOrgText)
    .addSmokeTest()
    .addInfoSectionHeader(infoboxHeaderText)
    .addInfoSection(explanationHeaderText)
    .addLinkSettings()
    .addReadMoreSchibstedSection()
    .addAdIdSection()
    .addBottomButtonSection();
};

const getTemplate = (rendererModalParams: RendererModalParams): ModalBuilder => {
  const {
    siteConfig: { polaris, marketplace }
  } = rendererModalParams;

  if (polaris) {
    return buildModalPolaris(rendererModalParams);
  }

  if (marketplace) {
    return buildModalMarketPlaces(rendererModalParams);
  }

  return buildModal(rendererModalParams);
};

const renderModal = (rendererModalParams: RendererModalParams): void => {
  const modalHTML = getTemplate(rendererModalParams).build();

  removeOldModalIfNeeded();
  document.body.insertAdjacentHTML('beforeend', modalHTML);
};

export { renderModal };
