import { Links } from '../interface/links';

const privacyLinks: Links = {
  SV: {
    READ_MORE_MATCH_ADVERTISES: {
      url: 'https://info.privacy.schibsted.com/se/gemensamt-personuppgiftsansvar-inom-ramen-for-schibsted-match/',
      text: 'Läs mer om våra Schibsted Match-annonsörer.'
    },
    READ_MORE_ADVERTISING: {
      url: 'https://info.privacy.schibsted.com/se/nastan-allt-som-finns-att-veta-om-personaliserad-reklam/',
      text: 'Läs mer om annonsering på Schibsted'
    },
    PRIVACY_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'här.' },
    MANAGE_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'Hantera dina annonsinställningar' }
  },
  NO: {
    READ_MORE_MATCH_ADVERTISES: {
      url: 'https://info.privacy.schibsted.com/no/felles-behandlingsansvar-i-forbindelse-med-schibsted-match/',
      text: 'Les mer om våre Schibsted Match annonsører.'
    },
    READ_MORE_ADVERTISING: {
      url: 'https://info.privacy.schibsted.com/no/nesten-alt-du-trenger-a-vite-om-personalisert-annonsering/',
      text: 'Les mer om annonsering på Schibsted'
    },
    PRIVACY_SETTINGS: { url: 'https://privacysettings.schibsted.no/', text: 'her.' },
    MANAGE_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'Administrer dine annonseinnstillinger' }
  },
  DK: {
    READ_MORE_MATCH_ADVERTISES: {
      url: 'https://info.privacy.schibsted.com/en/joint-controllership-in-the-context-of-schibsted-match/',
      text: 'Read more about our Schibsted Match advertisers.'
    },
    READ_MORE_ADVERTISING: {
      url: 'https://info.privacy.schibsted.com/en/schibsted-media-schibsted-marketplaces-data-and-you/',
      text: 'Read more about advertising at Schibsted'
    },
    PRIVACY_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'here.' },
    MANAGE_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'Manage your ad settings' }
  },
  FI: {
    READ_MORE_MATCH_ADVERTISES: {
      url: 'https://info.privacy.schibsted.com/en/joint-controllership-in-the-context-of-schibsted-match/',
      text: 'Read more about our Schibsted Match advertisers.'
    },
    READ_MORE_ADVERTISING: {
      url: 'https://info.privacy.schibsted.com/en/schibsted-media-schibsted-marketplaces-data-and-you/',
      text: 'Read more about advertising at Schibsted'
    },
    PRIVACY_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'here.' },
    MANAGE_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'Manage your ad settings' }
  },
  EN: {
    READ_MORE_MATCH_ADVERTISES: {
      url: 'https://info.privacy.schibsted.com/en/joint-controllership-in-the-context-of-schibsted-match/',
      text: 'Read more about our Schibsted Match advertisers.'
    },
    READ_MORE_ADVERTISING: {
      url: 'https://info.privacy.schibsted.com/en/schibsted-media-schibsted-marketplaces-data-and-you/',
      text: 'Read more about advertising at Schibsted'
    },
    PRIVACY_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'here.' },
    MANAGE_SETTINGS: { url: 'https://privacysettings.schibsted.com/', text: 'Manage your ad settings' }
  }
};

export { privacyLinks };
